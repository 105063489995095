import React from 'react'
import { Link } from 'gatsby'
import { slide as Menu } from 'react-burger-menu'

var styles = {
    bmBurgerButton: {
      position: 'absolute',
      width: '2rem',
      height: '1.8rem',
      right: '3rem',
      top: '2.5rem',
      background: 'rgba(0, 0, 0, 1)'
    },
    bmBurgerBars: {
      background: '#D1D5DB'
    },
    bmBurgerBarsHover: {
      background: '#D1D5DB'
    },
    bmCrossButton: {
      height: '48px',
      width: '48px'
    },
    bmCross: {
      background: '#bdc3c7'
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: '#373a47',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em'
    },
    bmMorphShape: {
      fill: '#373a47'
    },
    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em'
    },
    bmItem: {
      display: 'inline-block'
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
      right: '20%'
    }
  }

const nav = () => {
    return (
        <div class="container box-border py-7 flex flex-row content-center justify-between laptop:relative">
            <div>
                <Link to="/">
                    <h1 class="text-6xl text font-semibold px-10 md:px-0 hover:text-yellow cursor-pointer ">AK</h1>
                </Link>
            </div>
            <div class="visible absolute inset-x-0 top-0 justify-end px-4 self-center laptop:invisible">
                <Menu styles={ styles } right disableOverlayClick>
                    <div class="w-auto">
                        <Link to="/">
                            <p class="text-3xl text-yellow hover:text-gray-white cursor-pointer my-5">home</p>
                        </Link>
                        <Link to="/about">
                            <p class="text-3xl text hover:text-yellow cursor-pointer my-5">about me</p>
                        </Link>
                        <Link to="/projects" >
                            <p class="text-3xl text hover:text-yellow cursor-pointer my-5">my projects</p>
                        </Link>
                        <Link to="/contact">
                            <p class="text-3xl text hover:text-yellow cursor-pointer my-5">contact me</p>
                        </Link>
                    </div>
                </Menu>
            </div>
            <div class="hidden laptop:flex container w-1/2 flex-row justify-around flex-wrap content-center">
                <Link to="/about">
                    <p class="text-2xl text hover:text-yellow cursor-pointer">about me</p>
                </Link>
                <Link to="/projects" >
                    <p class="text-2xl text hover:text-yellow cursor-pointer">my projects</p>
                </Link>
                <Link to="/contact">
                    <p class="text-2xl text hover:text-yellow cursor-pointer">contact me</p>
                </Link>
            </div>
            
        </div>
    )
}

export default nav
